import React, { StrictMode } from "react";
// import { config } from "./config";

export const App = () => {
  return (
    <StrictMode>
      <h1>Hello New Relic! 2</h1>
    </StrictMode>
  );
};

// if (config.newRelic.enabled) {
//   import("./newrelic.min.js");
// }
